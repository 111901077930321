<template>
  <modal-component
    :modal-id="modalId"
    :title="`${abilityId !== 0 ? 'Edit' : 'Add'} Abilities`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
    @closeModal="closeModal"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
        <div class="form-group required mb-2">
          <label for="plan-name" class="fs-6 fw-bold mb-1">Name</label>
          <ce-text type="text" v-model="form.name"/>
        </div>
         <div class="form-group required mb-2">
          <label for="plan-name" class="fs-6 fw-bold mb-1">Label</label>
          <ce-text type="text" v-model="form.label"/>
        </div>
         <div class="form-group required mb-2">
          <label for="plan-name" class="fs-6 fw-bold mb-1">Group</label>
          <select class="form-select" v-model="form.group">
            <option :value="`users`">Users</option>
            <option :value="`groups`">Groups</option>
            <option :value="`events`">Events</option>
            <option :value="`talk`">Talk</option>
            <option :value="`badge`">Badge</option>
          </select>
        </div>
         <div class="form-group required mb-2">
          <label for="plan-name" class="fs-6 fw-bold mb-1">Info</label>
          <ce-text type="text" v-model="form.hint"/>
        </div>
        <div class="form-group required mb-2 row">
          <label for="plan-name" class="col-md-10 fs-6 fw-bold mb-1">Is Featured?</label>
          <div class="col-md-2 float-right">
            <is-important-form v-model="form.isImportant" />
          </div>
        </div>

         <div class="form-group required mb-2 row">
          <label for="plan-name" class="col-md-10 fs-6 fw-bold mb-1">Status</label>
          <div class="col-md-2 float-right">
            <status v-model="form.status"/>
          </div>
        </div>
        <div class="form-group required mb-2">
          <label for="plan-name" class="fs-6 fw-bold mb-1">Sort Number</label>
          <ce-text type="number" v-model="form.sortNumber"/>
        </div>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link text-decoration-none text-secondary"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link text-decoration-none text-primary"
          @click="nextAction"
          data-dismiss="modal"
        >
          Submit
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { computed } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import CeText from '../Inputs/CeText.vue';
import IsImportantForm from '@/Modules/Subscription/IsImportantForm.vue';
import Status from '@/Modules/Subscription/Status.vue';
// import { useStore } from 'vuex'

export default {
  components: {
    ModalComponent,
    CeText,
    IsImportantForm,
    Status,
  },
  props: {
    modalId: {
      type: String,
      default: "",
    },
    abilityId: {
      type: Number,
      default: 0,
    },
    formData: {
      type: [Array, Object],
      required: false,
      default: () => []
    }
  },
  emits: ["actionSubmit", "closeModal"],
  setup(props, { emit }) {
    // const store = useStore();

    const form = computed(() => ({
      id: props.formData?.id || '',
      name: props.formData?.name || '',
      label: props.formData?.label || '',
      group: props.formData?.group || '',
      hint: props.formData?.hint || '',
      isImportant: (parseInt(props.formData?.is_important) === 1 ? true : false) || false,
      sortNumber: props.formData?.sort_number || '',
      status: (parseInt(props.formData?.status) === 1 ? true : false) || false,
    }));

    const nextAction = () => {
      emit("actionSubmit", form);
    };

    const closeModal = () => {
      emit("closeModal", form);
    }

    return {
      form,
      nextAction,
      closeModal,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/custom";

.field-highlight {
  background: $color-light-gray;
  border-radius: 6px;
  padding: 0.5rem 0.875rem;
}
.input-group-text {
  background-color: #e7e7e7 !important;
}
:deep(.form-text.text-red) {
  font-size: 14px !important;
  font-weight: bold;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.list-suggestion-container {
  position: absolute;
  background: $color-white;
  height: 210px;
  width: 95%;
  z-index: 1;
  padding: 5px;
}
.list-suggestion-body {
  height: 180px;
  overflow-x: auto;
}
.close-suggestion {
  position: absolute;
  bottom: 0;
  right: 0;
}
.close-suggestion,.subscriber:hover {
 cursor: pointer;
}

</style>
